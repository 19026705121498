import {AGGREGATOR} from "@atg-casino-shared/types-vendor";
import {type Game, GAME_CATEGORY} from "@atg-casino-shared/types-game";
import {getOriginRoot, getBaseV2Url, getAccessChannel} from "./vendor.launch-url.helpers";
import {getDeviceType} from "./vendor.helpers";

interface UrlBuilderV2Props {
    game: Game;
    endpoint: "private" | "public";
    playForFun: boolean;
}

// the originRoot parameter needs to be appended last to the URL;
// otherwise, it will break the generic fetchAuthorized() call inside game.flow.saga.ts.
export const getLaunchUrlV2 = ({
    game,
    endpoint,
    playForFun,
}: UrlBuilderV2Props): string => {
    const originRoot = getOriginRoot();
    const {aggregateId, vendorGameId, type, tableId} = game;
    const baseUrl = getBaseV2Url(endpoint === "private", aggregateId);

    const gameCategory = type ?? GAME_CATEGORY.SLOT;
    const tableIdParam = tableId ? `&tableId=${tableId}` : "";

    let additionalParams = `?vendorGameId=${vendorGameId}&accessChannel=${getAccessChannel()}&deviceType=${getDeviceType()}&channel=${getDeviceType()}`;

    switch (aggregateId) {
        case AGGREGATOR.EVOLUTION:
            additionalParams += `&playMode=${
                playForFun ? "play_for_fun" : "real_money"
            }${tableIdParam}&gameType=${gameCategory}&channel=${getDeviceType()}`;
            break;

        case AGGREGATOR.PRAGMATICPLAY:
            additionalParams += `&gameCategory=${gameCategory}&originRoot=${originRoot}`;
            break;

        case AGGREGATOR.PLAYNGO:
            additionalParams += `&originRoot=${originRoot}`;
            break;

        case AGGREGATOR.EVERYMATRIX: {
            additionalParams += `&tableId=${tableId}&originRoot=${originRoot}`;
            break;
        }

        default:
            break;
    }

    const url = baseUrl + additionalParams;

    return url;
};
