import root from "window-or-global";
import {get} from "lodash";
import {isAndroid} from "@atg/utils/device";
import {AGGREGATOR} from "@atg-casino-shared/types-vendor";

const environment = root?.clientConfig?.env ?? "qa";
const atgApiSubdomain = `api${environment !== "prod" ? `.${environment}.hh` : ""}`;
const isApp = root.clientConfig?.systemName === "ATGApp";

export const getAccessChannel = (): string => {
    if (!isApp) return "web";

    return isAndroid() ? "android" : "ios";
};

export const getOriginRoot = () => {
    const origin =
        (isApp ? get(root, "clientConfig.AWS_MOBILE_APP_URL") : root.location.origin) ||
        "";

    // enable launching game through localhost
    if (origin.includes("localhost")) return "https://www.test3.hh.atg.se";

    return origin;
};

// Standardizes the gateway name for the public API endpoint, handling special cases like Thunderkick, append more if needed.
const formatGatewayPath = (gateway: string) => {
    switch (gateway) {
        case AGGREGATOR.THUNDERKICK:
            return "thunder-kick";

        default:
            return gateway;
    }
};

export const getBaseV2Url = (privateEndpoint: boolean, gateway: string) =>
    privateEndpoint
        ? `/services/tokenized/${gateway}/api/v2/launch`
        : `https://${atgApiSubdomain}.atg.se/${formatGatewayPath(
              gateway,
          )}/api-public/v2/launch`;
