import root from "window-or-global";

export const WIN_ANIMATION_COUNTER_DURATION = 5; // in seconds

export const WIN_EVENT_CLEAR_TIMEOUT_LONG = 8000;
export const WIN_EVENT_CLEAR_TIMEOUT = 4000;
export const JACKPOT_LOOP_INTERVAL = 4000;

export const CLEAR_JACKPOT_ERROR = "casino/CLEAR_JACKPOT_ERROR";

export const WIN_EVENT_CURRENT_USER = "casino/WIN_EVENT_CURRENT_USER";
export const WIN_EVENT_OTHER_USER = "casino/WIN_EVENT_OTHER_USER";

export const CLEAR_WIN_EVENT = "casino/CLEAR_WIN_EVENT";

export const UPDATE_ATG_JACKPOT = "casino/UPDATE_ATG_JACKPOT";
export const REMOVE_ATG_JACKPOT = "casino/REMOVE_ATG_JACKPOT";

export const REQUEST_OPT_IN = "casino/REQUEST_OPT_IN";
export const RECEIVE_OPT_IN = "casino/RECEIVE_OPT_IN";

export const REQUEST_ATG_JACKPOT_LOG_IN = "casino/REQUEST_ATG_JACKPOT_LOG_IN";
export const RECEIVE_ATG_JACKPOT_LOG_IN = "casino/RECEIVE_ATG_JACKPOT_LOG_IN";

export const REQUEST_FETCH_ATG_JACKPOTS = "casino/REQUEST_FETCH_ATG_JACKPOTS";
export const RECEIVE_FETCH_ATG_JACKPOTS = "casino/RECEIVE_FETCH_ATG_JACKPOTS";

export const ATG_JACKPOT_OPT_IN_ENDPOINT =
    "/services/tokenized/everymatrix/api/v1/jackpot/optin/status";

export const ATG_JACKPOT_LOG_IN_ENDPOINT =
    "/services/tokenized/everymatrix/api/v1/jackpot/login";

export const jpeUrl = root.clientConfig?.casino?.atgJackpot?.jpeUrl;

const environment = root?.clientConfig?.env ?? "qa";
const atgApiSubdomain = `api${environment !== "prod" ? `.${environment}.hh` : ""}`;

export const FETCH_JACKPOTS_URL = `https://${atgApiSubdomain}.atg.se/everymatrix/api-public/v1/jackpot`;
