import {call, select, put, take, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import * as Storage from "@atg-shared/storage";
import {LOGIN_FINISHED, LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import type {UsersLastTimeModalViewed} from "@atg-aml-shared/kyc-types";
import {ModalRequiredStatus} from "@atg-aml-shared/kyc-types";
import * as ModalActions from "atg-modals/modalActions";
import {frameAction, broadcastAction} from "atg-store-addons";
import * as ModalSelectors from "atg-modals/modalSelectors";
import * as KycQuestionnaireActions from "../actions/kycQuestionnaireActions";
import * as KycQuestionnaireActionsTypes from "../actions/kycQuestionnaireActionsTypes";
import {
    isCanSeeKycQuestionnaire,
    getKycModalRequired,
    isModalRequiredOnLogin,
    isKycModalBlock,
} from "../selectors/kycQuestionnaireSelectors";

// Its Frame MicroFE that calls on this
export function* shouldShowKycModalOnLogin(): SagaIterator {
    yield put(KycQuestionnaireActions.fetchCanSeeQuestionnaire());
    yield take(KycQuestionnaireActionsTypes.FETCH_CAN_SEE_QUESTIONNAIRE_RECEIVE);

    const canSeeQuestionnaire: boolean = yield select(isCanSeeKycQuestionnaire);
    if (!canSeeQuestionnaire) return;

    yield put(KycQuestionnaireActions.fetchModalRequired());
    yield take(KycQuestionnaireActionsTypes.FETCH_MODAL_REQUIRED_RECEIVE);

    const modalIsRequiredOnLogin: boolean = yield select(isModalRequiredOnLogin);
    if (!modalIsRequiredOnLogin) return;

    const isSummaryModalOpen: boolean = yield select(
        ModalSelectors.showUserGamblingSummaryModal,
    );
    if (isSummaryModalOpen) {
        yield take(ModalActions.closeUserGamblingSummaryModal().type);
    }

    // If the user is required too see the kyc form, then the AML microFE will load with the acutal modal
    yield put(ModalActions.showKycQuestionnaireModal());
}

function* seeKycInlineQuestionnaire() {
    yield put(KycQuestionnaireActions.fetchCanSeeQuestionnaire());
}

export function* kycModalFlow() {
    yield put(KycQuestionnaireActions.fetchModalRequired());
    yield take(KycQuestionnaireActionsTypes.FETCH_MODAL_REQUIRED_RECEIVE);

    yield put(KycQuestionnaireActions.fetchKycQuestionnaireQuestions());
    yield take(KycQuestionnaireActionsTypes.FETCH_KYC_QUESTIONNAIRE_QUESTION_RECEIVE);
    yield put(ModalActions.showKycQuestionnaireModal());
}

export function* waitForKycQuestionnaireModalClosed() {
    yield take(ModalActions.closeKycQuestionnaireModal().type);
}

export function* showKycModalBeforeBet() {
    yield put(frameAction(ModalActions.showKycQuestionnaireModal()));
}

export function* kycFlowBeforeBet() {
    yield put(KycQuestionnaireActions.fetchCanSeeQuestionnaire());
    yield take(KycQuestionnaireActionsTypes.FETCH_CAN_SEE_QUESTIONNAIRE_RECEIVE);
    const canSeeQuestionnaire: boolean = yield select(isCanSeeKycQuestionnaire);
    if (!canSeeQuestionnaire) return;

    yield put(KycQuestionnaireActions.fetchModalRequired());
    yield take(KycQuestionnaireActionsTypes.FETCH_MODAL_REQUIRED_RECEIVE);
    const isBlocked: boolean = yield select(isKycModalBlock);
    if (!isBlocked) return;

    yield put(frameAction(ModalActions.showKycQuestionnaireModal()));
}

function* closeQuestionnaireAndShowFinshedModal() {
    yield put(ModalActions.closeKycQuestionnaireModal());
    yield put(ModalActions.showKycQuestionnaireFinishedModal());
}

function* editKycQuestionnaireAppFlow(
    action: KycQuestionnaireActions.SetKycQuestionnaireQuestionsAction,
) {
    // @ts-ignore
    if (action.error) return;
    yield put(KycQuestionnaireActions.fetchQuestionnaireMostRecent());
    yield call(closeQuestionnaireAndShowFinshedModal);
    yield put(KycQuestionnaireActions.resetKycQuestionnaire());
}

function* editKycQuestionnaireFlow(
    action: KycQuestionnaireActions.SetKycQuestionnaireQuestionsAction,
) {
    // @ts-ignore
    if (action.error) return;
    yield put(KycQuestionnaireActions.fetchQuestionnaireMostRecent());
    yield put(KycQuestionnaireActions.resetKycQuestionnaire());
}

function* storeKycQuestionnaireShowAt(
    action: KycQuestionnaireActions.UpdatedTimeWhenCustomerSawKycAfterLoginAction,
) {
    const modalRequired: ModalRequiredStatus = yield select(getKycModalRequired);
    if (modalRequired === ModalRequiredStatus.REMINDER) {
        yield Storage.setItem(
            KycQuestionnaireActionsTypes.UPDATED_TIME_WHEN_CUSTOMER_SAW_KYC_AFTER_LOGIN,
            JSON.stringify(action.payload),
        );
    } else {
        yield Storage.removeItem(
            KycQuestionnaireActionsTypes.UPDATED_TIME_WHEN_CUSTOMER_SAW_KYC_AFTER_LOGIN,
        );
    }
}
function* setKycQuestionnaireShowAtFromStorage() {
    const storedUsersLastTimeModalViewed: string | null = yield Storage.getItem(
        KycQuestionnaireActionsTypes.UPDATED_TIME_WHEN_CUSTOMER_SAW_KYC_AFTER_LOGIN,
    );
    if (storedUsersLastTimeModalViewed) {
        const usersLastTimeModalViewed: UsersLastTimeModalViewed = JSON.parse(
            storedUsersLastTimeModalViewed,
        );
        yield put(
            KycQuestionnaireActions.updatedTimeWhenCustomerSawKycAfterLogin(
                usersLastTimeModalViewed,
            ),
        );
    }
}

export function* isKycBlockForBetting(): SagaIterator<boolean> {
    yield put(KycQuestionnaireActions.fetchCanSeeQuestionnaire());
    yield take(KycQuestionnaireActionsTypes.FETCH_CAN_SEE_QUESTIONNAIRE_RECEIVE);
    const canSeeQuestionnaire: boolean = yield select(isCanSeeKycQuestionnaire);
    if (!canSeeQuestionnaire) return false;

    yield put(KycQuestionnaireActions.fetchModalRequired());
    yield take(KycQuestionnaireActionsTypes.FETCH_MODAL_REQUIRED_RECEIVE);
    const isKycBlock: boolean = yield select(isKycModalBlock);

    return isKycBlock;
}

export function* closeKycQuestionnaireModal() {
    yield put(frameAction(broadcastAction(ModalActions.closeKycQuestionnaireModal())));
}

function* resetKycState() {
    yield put(frameAction(broadcastAction(KycQuestionnaireActions.resetKycState())));
}

export function* kycQuestionnaireFrameSaga() {
    yield takeLatest(LOGIN_FINISHED, shouldShowKycModalOnLogin);
    yield takeLatest(LOGOUT_FINISHED, resetKycState);
}

export function* kycQuestionnairePaymentSaga() {
    yield takeLatest(
        KycQuestionnaireActionsTypes.CAN_SEE_KYC_QUESTIONNAIRE_IN_ACCOUNT,
        seeKycInlineQuestionnaire,
    );
}

export function* kycQuestionnaireAmlSaga() {
    yield takeLatest(
        KycQuestionnaireActionsTypes.START_KYC_QUESTIONNAIRE_FLOW,
        kycModalFlow,
    );
    yield takeLatest(
        [
            KycQuestionnaireActionsTypes.CLOSE_KYC_QUESTIONNAIRE_FLOW,
            KycQuestionnaireActionsTypes.FINISH_KYC_QUESTIONNAIRE_FLOW,
        ],

        closeKycQuestionnaireModal,
    );
    yield takeLatest(
        KycQuestionnaireActionsTypes.KYC_QUESTIONNAIRE_ANSWERS_RECEIVE,
        editKycQuestionnaireFlow,
    );
}

export function* kycQuestionnaireBeforeBetSaga() {
    yield takeLatest(
        KycQuestionnaireActionsTypes.CAN_SEE_KYC_QUESTIONNAIRE_BEFORE_BET,
        showKycModalBeforeBet,
    );
}

export function* kycQuestionnaireAppSaga() {
    yield takeLatest(LOGIN_FINISHED, shouldShowKycModalOnLogin);
    yield takeLatest(
        [
            LOGIN_FINISHED,
            KycQuestionnaireActionsTypes.FETCH_KYC_QUESTIONNAIRE_QUESTION_REQUEST,
        ],
        setKycQuestionnaireShowAtFromStorage,
    );
    yield takeLatest(LOGOUT_FINISHED, resetKycState);
    yield takeLatest(
        KycQuestionnaireActionsTypes.KYC_QUESTIONNAIRE_ANSWERS_RECEIVE,
        editKycQuestionnaireAppFlow,
    );
    yield takeLatest(
        KycQuestionnaireActionsTypes.CAN_SEE_KYC_QUESTIONNAIRE_IN_ACCOUNT,
        seeKycInlineQuestionnaire,
    );
    yield takeLatest(
        [
            KycQuestionnaireActionsTypes.FINISH_KYC_QUESTIONNAIRE_FLOW,
            KycQuestionnaireActionsTypes.CLOSE_KYC_QUESTIONNAIRE_FLOW,
        ],

        closeKycQuestionnaireModal,
    );
    yield takeLatest(
        KycQuestionnaireActionsTypes.CAN_SEE_KYC_QUESTIONNAIRE_BEFORE_BET,
        showKycModalBeforeBet,
    );
    yield takeLatest(
        KycQuestionnaireActionsTypes.UPDATED_TIME_WHEN_CUSTOMER_SAW_KYC_AFTER_LOGIN,
        storeKycQuestionnaireShowAt,
    );
}
