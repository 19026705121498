import {device} from "@atg/utils";
import {isApp} from "@atg-shared/system";
import {AGGREGATOR, PLAY_FOR_FUN_AGGREGATORS} from "@atg-casino-shared/types-vendor";
import {type Game, GAME_CATEGORY} from "@atg-casino-shared/types-game";

export const getDeviceType = () => {
    // This is needed for qlikview to determine that the start
    // came from the mobile app.
    if (isApp) {
        return "atgapp";
    }

    // This is for atg.se mobile and not atgapp
    if (device.isDevice()) {
        return "mobile";
    }

    return "desktop";
};

export const isPlayForFunSupported = ({aggregateId, type}: Game) =>
    PLAY_FOR_FUN_AGGREGATORS[aggregateId] &&
    !(type === GAME_CATEGORY.LIVE_CASINO && aggregateId === AGGREGATOR.EVOLUTION) &&
    !(type === GAME_CATEGORY.BINGO_GAME && aggregateId === AGGREGATOR.PRAGMATICPLAY);
