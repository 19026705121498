import type {AtgJackpot} from "@atg-casino-shared/types-atg-jackpot";
import {FETCH, call} from "@atg-shared/fetch-redux";
import {fetchAuthorized} from "@atg-shared/auth";
import {pureFetch} from "@atg-shared/fetch";
import * as constants from "../constants";
import type {
    ClearJackpotErrorAction,
    ClearWinEventAction,
    FetchAtgJackpotsAction,
    HandleWinEventAction,
    JackpotErrorType,
    JackpotWinEvent,
    LogInAction,
    OptInAction,
} from "../types";

export const handleWinEvent = ({
    event,
    jackpotName,
    jackpotPlayerId,
}: {
    event: JackpotWinEvent;
    jackpotName?: string;
    jackpotPlayerId?: number;
}): HandleWinEventAction => ({
    type:
        // conversion our jackpotPlayerId to match the userid from win event
        jackpotPlayerId === event.userId
            ? constants.WIN_EVENT_CURRENT_USER
            : constants.WIN_EVENT_OTHER_USER,
    payload: {
        jackpotId: event.jackpotId,
        jackpotName,
        amount: event.amount,
        currency: event.currency,
    },
});

export const updateAtgJackpot = (payload: AtgJackpot) => ({
    type: constants.UPDATE_ATG_JACKPOT,
    payload,
});

export const removeAtgJackpot = (payload: {jackpotId: string}) => ({
    type: constants.REMOVE_ATG_JACKPOT,
    payload,
});

export const optInAction = ({optedIn}: {optedIn: boolean}): OptInAction => ({
    type: FETCH,
    payload: {
        requestAction: constants.REQUEST_OPT_IN,
        receiveAction: constants.RECEIVE_OPT_IN,
        callApi: call(fetchAuthorized, constants.ATG_JACKPOT_OPT_IN_ENDPOINT, {
            method: "PUT",
            body: JSON.stringify({
                optedIn,
            }),
        }),
    },
});

export const atgJackpotLogin = ({deviceType}: {deviceType: string}): LogInAction => ({
    type: FETCH,
    payload: {
        requestAction: constants.REQUEST_ATG_JACKPOT_LOG_IN,
        receiveAction: constants.RECEIVE_ATG_JACKPOT_LOG_IN,
        callApi: call(fetchAuthorized, constants.ATG_JACKPOT_LOG_IN_ENDPOINT, {
            method: "POST",
            body: JSON.stringify({
                deviceType: deviceType.toUpperCase(),
            }),
        }),
    },
});

export const clearWinEvent = ({
    currentUser,
}: {
    currentUser: boolean;
}): ClearWinEventAction => ({
    type: constants.CLEAR_WIN_EVENT,
    payload: {currentUser},
});

export const clearJackpotError = ({
    type,
}: {
    type: JackpotErrorType;
}): ClearJackpotErrorAction => ({
    type: constants.CLEAR_JACKPOT_ERROR,
    payload: {type},
});

export const fetchAtgJackpots = (): FetchAtgJackpotsAction => ({
    type: FETCH,
    payload: {
        requestAction: constants.REQUEST_FETCH_ATG_JACKPOTS,
        receiveAction: constants.RECEIVE_FETCH_ATG_JACKPOTS,
        callApi: call(pureFetch, constants.FETCH_JACKPOTS_URL, {
            method: "GET",
        }),
    },
});
